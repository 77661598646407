// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root[data-theme="light"] {
  --color-text-input: #393939;
  }
  
  :root[data-theme="dark"] {
    --color-text-input:  #ffffff;
  }

.mainStyle
{
    width: 335px;
    padding: 14px 12px;
    align-items: flex-start;
    font-family: 'SuisseIntl';
    font-size: 16px; 
    font-style: normal;
    font-weight: 400;
    resize: none;
    background-color: transparent;
    font-feature-settings: 'clig' off, 'liga' off; 
    gap: 10px; 
    border-radius: 14px;
    border: 1px solid var(--Stroke, #DEDEDE);
}

.mainStyle:focus
{
    background-color: transparent;
    border:1px solid var(--Stroke, #DEDEDE) ;
    outline: none;

}

.textInput
{
    color: var(--color-text-input);
    text-align: center;
    font-family: 'SuisseIntl';
    font-size: 16px;
    font-style: normal;
    font-weight: 400px;
    line-height: 21px; /* 143.75% */ 
    width: 335px;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/InputComponent/InputComp.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B;;EAEA;IACE,4BAA4B;EAC9B;;AAEF;;IAEI,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,6BAA6B;IAC7B,6CAA6C;IAC7C,SAAS;IACT,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;;IAEI,6BAA6B;IAC7B,wCAAwC;IACxC,aAAa;;AAEjB;;AAEA;;IAEI,8BAA8B;IAC9B,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB,EAAE,YAAY;IAC/B,YAAY;IACZ,SAAS;AACb","sourcesContent":[":root[data-theme=\"light\"] {\r\n  --color-text-input: #393939;\r\n  }\r\n  \r\n  :root[data-theme=\"dark\"] {\r\n    --color-text-input:  #ffffff;\r\n  }\r\n\r\n.mainStyle\r\n{\r\n    width: 335px;\r\n    padding: 14px 12px;\r\n    align-items: flex-start;\r\n    font-family: 'SuisseIntl';\r\n    font-size: 16px; \r\n    font-style: normal;\r\n    font-weight: 400;\r\n    resize: none;\r\n    background-color: transparent;\r\n    font-feature-settings: 'clig' off, 'liga' off; \r\n    gap: 10px; \r\n    border-radius: 14px;\r\n    border: 1px solid var(--Stroke, #DEDEDE);\r\n}\r\n\r\n.mainStyle:focus\r\n{\r\n    background-color: transparent;\r\n    border:1px solid var(--Stroke, #DEDEDE) ;\r\n    outline: none;\r\n\r\n}\r\n\r\n.textInput\r\n{\r\n    color: var(--color-text-input);\r\n    text-align: center;\r\n    font-family: 'SuisseIntl';\r\n    font-size: 16px;\r\n    font-style: normal;\r\n    font-weight: 400px;\r\n    line-height: 21px; /* 143.75% */ \r\n    width: 335px;\r\n    gap: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
