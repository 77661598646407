import React from "react";
import './modalEnd.css';
import elipse from './success.svg';
import successDerk from './success_dark.svg';
import { Appearance } from 'react-native';

const Modal = ({active}) =>
{
    let textmodal2 = "Спасибо,"
    let textModal = "ваши отзывы помогают ";
    let textModal1 = "нам стать лучше для вас!"
    const colorScheme = Appearance.getColorScheme();

    let successSvgName = <img src={elipse}/>;
    
    if(colorScheme === 'dark')
    {
        successSvgName = <img src={successDerk}/>;
    }
    
    return (
        <div className="modal__content">
        <div className="modal__img">
        {successSvgName}
        </div>
        <div className="modal__text">
            {textmodal2}
            <br/>
            {textModal}
            <br/>
            {textModal1}
        </div>
        </div>
            );

}

export default Modal;
