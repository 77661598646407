import React from 'react'
import { ThemeContext, themes } from '../contexts/themeContext'

const ThemeProvider = ({ children, themeNew }) => {
  const [ theme, setTheme ] = React.useState(themeNew)

  React.useEffect(() => {
    document.documentElement.dataset.theme = theme;
    //localStorage.setItem('theme', theme);
  }, [ theme ])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider