// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/PFAgoraSlabPro-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'FAgoraSlabPro';
    src: local('FAgoraSlabPro'),
      url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: bold;

  }

  .font-dancing-script {
    font-family: "FAgoraSlabPro";
  }

  .font-dancing-script {
    font-family: "FAgoraSlabPro";
  }

  :root[data-theme="light"] {
    --background-color: #ffffff;
  }
  
  :root[data-theme="dark"] {
    --background-color: #053D35;
  }
  
  body{
    background: var(--background-color);
  }
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B;gEAC4D;IAC5D,iBAAiB;;EAEnB;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,mCAAmC;EACrC","sourcesContent":["@font-face {\r\n    font-family: 'FAgoraSlabPro';\r\n    src: local('FAgoraSlabPro'),\r\n      url('./fonts/PFAgoraSlabPro-Black.ttf') format('truetype');\r\n    font-weight: bold;\r\n\r\n  }\r\n\r\n  .font-dancing-script {\r\n    font-family: \"FAgoraSlabPro\";\r\n  }\r\n\r\n  .font-dancing-script {\r\n    font-family: \"FAgoraSlabPro\";\r\n  }\r\n\r\n  :root[data-theme=\"light\"] {\r\n    --background-color: #ffffff;\r\n  }\r\n  \r\n  :root[data-theme=\"dark\"] {\r\n    --background-color: #053D35;\r\n  }\r\n  \r\n  body{\r\n    background: var(--background-color);\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
