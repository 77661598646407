// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    /* light theme tokens */
    --background-primary-light: 44, 191, 170;
    --accent-light: 37, 89, 88;
  
    /* dark theme tokens */
    --background-primary-dark: 25, 48, 66;
    --accent-dark: 90, 182, 204;
  }
  
  /* map tokens to proper theme */
  [data-theme='light'] {
    --background-primary: var(--background-primary-light);
    --accent: var(--accent-light);
  }
  
  [data-theme='dark'] {
    --background-primary: var(--background-primary-dark);
    --accent: var(--accent-dark);
  }`, "",{"version":3,"sources":["webpack://./src/components/themes.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wCAAwC;IACxC,0BAA0B;;IAE1B,sBAAsB;IACtB,qCAAqC;IACrC,2BAA2B;EAC7B;;EAEA,+BAA+B;EAC/B;IACE,qDAAqD;IACrD,6BAA6B;EAC/B;;EAEA;IACE,oDAAoD;IACpD,4BAA4B;EAC9B","sourcesContent":[":root {\r\n    /* light theme tokens */\r\n    --background-primary-light: 44, 191, 170;\r\n    --accent-light: 37, 89, 88;\r\n  \r\n    /* dark theme tokens */\r\n    --background-primary-dark: 25, 48, 66;\r\n    --accent-dark: 90, 182, 204;\r\n  }\r\n  \r\n  /* map tokens to proper theme */\r\n  [data-theme='light'] {\r\n    --background-primary: var(--background-primary-light);\r\n    --accent: var(--accent-light);\r\n  }\r\n  \r\n  [data-theme='dark'] {\r\n    --background-primary: var(--background-primary-dark);\r\n    --accent: var(--accent-dark);\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
