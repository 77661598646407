import React, { useState} from "react";
import './star.css';
import star from './star_icon.svg'
import starFill from './star_icon_fill.svg'
import Input from '../InputComponent/InputComp'
//import SendRevMap from '../SendRevMap/SendRevMap'
import { useDispatch } from "react-redux";

function Star({urlMap, sorceUrlMap})
{
    const dispatch = useDispatch();

    const addCash = (cash) => 
    {
        dispatch({type: "ADD_STAR", payload: countstars})
    }

    let [countstars, setStars] = useState(0); 

    const handleCLick = (event, message) =>
    {
        setStars(message);
    }

    function GetStarFill(countStar)
    {
        let starFillImg = <img alt="" onClick={(event)=> handleCLick(event, countStar)} src={starFill}>
                            </img>;
        return starFillImg;
    }

    function GetMainStar(countStar)
    {
        let mainStar = <img alt="" onClick={(event)=> handleCLick(event, countStar)} src={star}>
        </img>;

        return mainStar;
    }

    const stars =
    [
        <img alt="" onClick={(event)=> handleCLick(event, 1)} src={star}>
        </img>,
        <img alt="" onClick={(event)=> handleCLick(event, 2)} src={star}>
        </img>,
        <img alt="" onClick={(event)=> handleCLick(event, 3)} src={star}>
        </img>,
        <img alt="" onClick={(event)=> handleCLick(event, 4)} src={star}>
        </img>,
        <img alt="" onClick={(event)=> handleCLick(event, 5)} src={star}>
        </img>,
    ];

    switch(countstars)
    {
        case 1:
            stars[0] = GetStarFill(1)
            stars[1] = GetMainStar(2)
            stars[2] = GetMainStar(3)
            stars[3] = GetMainStar(4)
            stars[4] = GetMainStar(5)
            break;
        case 2:
            stars[0] = GetStarFill(1)
            stars[1] = GetStarFill(2)
            stars[2] = GetMainStar(3)
            stars[3] = GetMainStar(4)
            stars[4] = GetMainStar(5)
            break;
        case 3:
            stars[0] = GetStarFill(1)
            stars[1] = GetStarFill(2)
            stars[2] = GetStarFill(3)
            stars[3] = GetMainStar(4)
            stars[4] = GetMainStar(5)
            break;
        case 4:
            stars[0] = GetStarFill(1)
            stars[1] = GetStarFill(2)
            stars[2] = GetStarFill(3)
            stars[3] = GetStarFill(4)
            stars[4] = GetMainStar(5)
            break;
        case 5:
            stars[0] = GetStarFill(1);
            stars[1] = GetStarFill(2);
            stars[2] = GetStarFill(3);
            stars[3] = GetStarFill(4);
            stars[4] = GetStarFill(5);

            break;

        default:
            break;
    }

    addCash();

    if(countstars < 5 && countstars > 0)
    {
    return(
        <div >
        <div className="stars">
        {stars}
        </div>
        <div>
            <h1></h1>
        </div>
        <Input></Input>
        </div>
    );
    }
    else if(countstars === 0)
    {
        return(
            <div>
                <div className="stars">
                {stars}
                </div>
            </div>
            );

    }

    // else if(urlMap !== null && urlMap !== "" && countstars === 5)
    //     {
    //         return(
    //             <div >
    //                 <div className="stars">
    //                     {stars}
    //                 </div>
    //                 <div>
    //                     <h1></h1>
    //                 </div>
    //                 <div>
    //                     <SendRevMap urlMap={urlMap} sourceUrlMap = {sorceUrlMap}>                       
    //                     </SendRevMap>
    //                 </div>
    //             </div>
    //         );
    //     }

    else
    {
        return(
            <div >
                <div className="stars">
                    {stars}
                </div>
                <div>
                    <h1></h1>
                </div>
                <div>
                    <Input></Input>
                </div>
            </div>
        );
    }
}

export default Star;