// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root[data-theme="light"] {
    --color-text: #393939;
  }
  
  :root[data-theme="dark"] {
    --color-text:  #ffffff;
  }



.textInfo
{
    color: var(--color-text);
    font-size: 16px;
    font-family: 'SuisseIntl';
    font-weight: 400;
    line-height: 21px;
    word-wrap: break-word;
    text-align: center;
    width: 335px;
}

.dep_name
{
    color: var(--color-text);
    font-family: 'SuisseIntl';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  
}

`, "",{"version":3,"sources":["webpack://./src/components/MainText/MainText.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;EACvB;;EAEA;IACE,sBAAsB;EACxB;;;;AAIF;;IAEI,wBAAwB;IACxB,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;;IAEI,wBAAwB;IACxB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;;AAErB","sourcesContent":[":root[data-theme=\"light\"] {\r\n    --color-text: #393939;\r\n  }\r\n  \r\n  :root[data-theme=\"dark\"] {\r\n    --color-text:  #ffffff;\r\n  }\r\n\r\n\r\n\r\n.textInfo\r\n{\r\n    color: var(--color-text);\r\n    font-size: 16px;\r\n    font-family: 'SuisseIntl';\r\n    font-weight: 400;\r\n    line-height: 21px;\r\n    word-wrap: break-word;\r\n    text-align: center;\r\n    width: 335px;\r\n}\r\n\r\n.dep_name\r\n{\r\n    color: var(--color-text);\r\n    font-family: 'SuisseIntl';\r\n    font-size: 16px;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    line-height: 21px;\r\n  \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
