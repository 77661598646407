import React, { useState, useRef, useEffect } from "react";
import './InputComp.css';
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from'../static/useWindowDimensions';
import { DifferenceWidth } from "../static/cons-breacpoint";
import { Appearance } from 'react-native';

const Component = () => {
    const { height, width } = useWindowDimensions();
  
    return ({height, width});
  };

function InputComp()
{
    const colorScheme = Appearance.getColorScheme(); 

    const [val, setVal] = useState("");
    const textAreaRef = useRef(null);

    const dispatch = useDispatch();
    const rev = useSelector(state => state.star);

    const addCash = () => 
    {
        dispatch({type: "ADD_REV", payload: val})
    }

    let widthTextArea = 335;
    let sizePage = Component();

    if(360 - sizePage.width > 0)
    {
        widthTextArea = sizePage.width - DifferenceWidth;
    }

    let textAreaRefColor = "#fff";

    if(colorScheme === "dark")
    {
        textAreaRefColor = "#ffffff";
    }
    else
    {
        textAreaRefColor = "#393939";
    }

    useEffect(() => {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        textAreaRef.current.style.width = widthTextArea + "px";
        textAreaRef.current.style.color = textAreaRefColor;
    }, [val]);

    const onChange = e => {
        setVal(e.target.value);
        addCash();
    };

    return(
        <div>
        <div className='textInput'>
          Расскажите, что вам не понравилось — так мы сможем стать лучше: 
        </div>
            <div>
                <h1></h1>
            </div>
            <textarea className="mainStyle" placeholder="Комментарий" 
            rows="1"
            onChange={onChange}
            value={val}
            ref={textAreaRef}>
            </textarea>
        </div>
    );
}

export default InputComp;